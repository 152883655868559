.confirm-payslip[data-v-4735f4ae] {
  padding: 0 0.1rem 0.15rem;
}
.confirm-payslip .confirm-payslip-container[data-v-4735f4ae] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.confirm-payslip .confirm-payslip-container .tips[data-v-4735f4ae] {
      font-size: 0.12rem;
      color: #a9a9ac;
      padding-bottom: 0.1rem;
}
.confirm-payslip .confirm-payslip-container .confirm-btn[data-v-4735f4ae] {
      width: 100%;
      height: 0.44rem;
      font-size: 0.16rem;
      border-radius: 0.04rem !important;
}
.confirm-payslip .confirm-payslip-container .auto-confirm[data-v-4735f4ae] {
      margin: 10px 0;
}
.confirm-payslip .confirm-payslip-container .auto-confirm span[data-v-4735f4ae] {
        color: #fd5e11;
}
.confirm-payslip .feedback-con[data-v-4735f4ae] {
    text-align: center;
}
.confirm-payslip .feedback-con .feedback-result[data-v-4735f4ae] {
      padding: 30px;
}
.confirm-payslip .feedback-con .image[data-v-4735f4ae] {
      margin-top: 10px;
      height: 0;
      overflow: hidden;
      padding-bottom: 59%;
}
.confirm-payslip .feedback-con .image > img[data-v-4735f4ae] {
        width: 100%;
}
.confirm-payslip .feedback-con .title[data-v-4735f4ae] {
      color: #3890ff;
      font-weight: bold;
      font-size: 20px;
      line-height: 50px;
}
.confirm-payslip .feedback-con .user-info[data-v-4735f4ae] {
      color: #3890ff;
      font-weight: bold;
}
@media screen and (min-width: 768px) {
.confirm-payslip[data-v-4735f4ae] {
    padding: 0 10px 15px;
}
.confirm-payslip .confirm-payslip-container .tips[data-v-4735f4ae] {
      font-size: 12px;
      padding-bottom: 10px;
}
.confirm-payslip .confirm-payslip-container .confirm-btn[data-v-4735f4ae] {
      height: 44px;
      font-size: 16px;
      border-radius: 4px !important;
      margin-bottom: 10px;
}
}
