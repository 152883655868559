.payslip-header[data-v-92088388] {
  width: 100%;
  height: 1.6rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
.payslip-header[data-v-92088388] {
    height: 160px;
}
}
