@charset "UTF-8";
.payslip-detail[data-v-4539b1a8] {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 0;
  /* 兜底方案 */
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
}
@media screen and (min-width: 768px) {
.payslip-detail[data-v-4539b1a8] {
    width: 375px;
    height: 667px;
    margin: 0 auto;
}
}
